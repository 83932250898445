import { redirect } from 'react-router-dom'
import { API_ROOM_BOOKINGS } from 'constants/api-v2'
import QueryClient from 'services/query'

interface Params {
  id: string
  lang: string
}

export const createBooking = (client: QueryClient) => (
  async ({ params, request }: { params: Params, request: Request }) => {
    const { id, lang } = params

    const data = await request.json()
    let resp

    try {
      /**
       * If we are copying we use POST to create a new Booking
       */
      if (!!id && !data.copy) {
        await client.put(`${API_ROOM_BOOKINGS}/${id}`, data)
      } else {
        resp = await client.post(API_ROOM_BOOKINGS, data)
      }
    } catch (e: Error | any) {
      return { error: e.response }
    }

    const bookingId = !!resp ? resp.id : id

    return redirect(`/${lang}/planning/${bookingId}/edit`)
  }
)
