import * as React from 'react'
import {
  BookFilled,
  DeleteFilled,
  PieChartFilled,
  ScheduleFilled,
  SettingFilled,
  SignalFilled,
  HddFilled,
  HistoryOutlined,
} from '@ant-design/icons'
import type { Settings } from 'shared/types'
import TrainingIcon from 'components/UI/Icons/TrainingIcon'
import MyPerimeterIcon from 'components/UI/Icons/MyPerimeterIcon'
import { permissions as p } from 'constants/permissions'
import dayjs from 'config/dayjs'
import type { MenuItem } from './types'

interface GetItemsProps {
  tableauURL: string
  perimeter: {
    'n-1': boolean
    'n-X': boolean
  }
  settings: Settings
}

export function getItems({ tableauURL, perimeter, settings }: GetItemsProps): MenuItem[] {
  const cascadeParam = (perimeter?.['n-1'] && perimeter?.['n-X']) ? '&cascade=false' : ''

  const reporting = tableauURL ? [{
    name: 'menu.reporting',
    href: tableauURL,
    icon: <PieChartFilled />,
    permissions: p.reporting,
  }] : []

  return [
    {
      name: 'menu.catalog',
      href: 'catalog',
      icon: <TrainingIcon />,
      permissions: p.catalog,
    }, {
      name: 'menu.trainings_management',
      href: 'training-management',
      icon: <TrainingIcon />,
      permissions: p.trainingManagement,
    }, {
      name: 'menu.collection_need',
      href: 'needs-summary',
      icon: <BookFilled />,
      permissions: p.needsSummary,
    }, {
      name: 'menu.collection_sessions',
      href: 'sessions',
      icon: <HddFilled />,
      permissions: p.sessionView,
    }, {
      name: 'menu.collection_reservations',
      href: `planning?date=${dayjs(new Date()).format('YYYY-MM-DD')}`,
      icon: <HddFilled />,
      permissions: p.roomBookingCanView,
      enabled: settings?.roomBookingEnabled,
    }, {
      name: 'menu.training_plan',
      href: 'training-plan',
      icon: <HddFilled />,
      permissions: p.trainingPlan,
    }, {
      name: 'menu.deleted_wishes',
      href: 'deleted-wishes',
      icon: <DeleteFilled />,
      permissions: p.deletedWishes,
    }, {
      name: 'menu.accreditation_board.hse',
      href: 'accreditations?user.enabled=true',
      icon: <ScheduleFilled />,
      permissions: p.accreditationBoard,
    }, {
      name: 'menu.accreditation_board',
      href: 'renewable-trainings?user.enabled=true',
      icon: <ScheduleFilled />,
      permissions: p.renewableTrainingBoard,
    }, {
      name: 'menu.collectives_management',
      href: 'collectives',
      icon: <SignalFilled />,
      permissions: p.collectives,
    }, {
      name: 'menu.perimeter',
      href: `my-perimeter?enabled=true${cascadeParam}`,
      icon: <MyPerimeterIcon />,
      permissions: p.myPerimeter,
    },
    {
      name: 'menu.training_history',
      href: `training-history?${cascadeParam}`,
      icon: <HistoryOutlined />,
      permissions: p.trainingHistory,
    },
    ...reporting,
  ]
}

export const adminItems: MenuItem[] = [
  {
    // Config
    name: 'menu.admin_settings',
    icon: <SettingFilled />,
    children: [
      {
        name: 'menu.admin_settings_profiles',
        href: 'admin/profiles',
        permissions: p.adminProfiles,
      }, {
        name: 'menu.admin_settings_subprofiles',
        href: 'admin/sub-profiles',
        permissions: p.adminSubProfiles,
      }, {
        name: 'menu.admin_settings_global',
        href: 'admin/settings',
        permissions: p.adminSettings,
      }, {
        name: 'admin.crud.collaborators.view',
        href: 'admin/collaborators',
        permissions: p.adminCollaborators,
      }, {
        name: 'menu.admin_doc_templates',
        href: 'admin/doc-templates',
        permissions: p.adminDocTemplates,
      }, {
        name: 'menu.admin_populations',
        href: 'admin/populations',
        permissions: p.adminPopulations,
      }, {
        name: 'menu.admin_applications',
        href: 'admin/applications',
        permissions: p.adminApplications,
      }, {
        name: 'menu.admin_training_perimeter',
        href: 'admin/perimeters/training',
        permissions: p.adminPerimetersTraining,
      }, {
        name: 'menu.admin_accreditation_perimeter',
        href: 'admin/perimeters/accreditation',
        permissions: p.adminPerimetersAccreditation,
      },
    ],
  }, {
    // Catalog
    name: 'menu.admin_training',
    icon: <SettingFilled />,
    permissions: p.adminTrainings,
    children: [
      {
        name: 'menu.admin_training_categories',
        href: 'admin/training/categories',
      }, {
        name: 'menu.admin_training_themes',
        href: 'admin/training/themes',
      }, {
        name: 'menu.admin_training_plans',
        href: 'admin/training/plans',
      }, {
        name: 'admin.crud.budgets.list',
        href: 'admin/training/budgets',
      }, {
        name: 'admin_trainers',
        href: 'admin/training/trainers',
      }, {
        name: 'menu.admin_teaching_methods',
        href: 'admin/teaching-methods',
      },
    ],
  }, {
    // Needs Summary
    name: 'menu.admin_wish',
    icon: <SettingFilled />,
    permissions: p.adminWishes,
    children: [
      {
        name: 'menu.admin_wish_reasons',
        href: 'admin/training-wish/reasons',
      }, {
        name: 'menu.admin_wish_perimeters',
        href: 'admin/training-wish/perimeters',
      }, {
        name: 'menu.admin_wish_orientations',
        href: 'admin/training-wish/orientations',
      }, {
        name: 'menu.admin_wish_types',
        href: 'admin/training-wish/types',
      }, {
        name: 'menu.admin_wish_refusal_motive',
        href: 'admin/training-wish/refusal-reasons',
      }, {
        name: 'menu.admin_vintages',
        href: 'admin/vintages',
        permissions: p.adminVintages,
      }, {
        name: 'menu.admin.priorities',
        href: 'admin/training-wish/priorities',
      }, {
        name: 'menu.admin_training_projects',
        href: 'admin/training/projects',
      }, {
        name: 'menu.admin_delete_reasons',
        href: 'admin/training-wish/delete-reasons',
      },
    ],
  }, {
    // Sessions
    name: 'menu.admin_session',
    icon: <SettingFilled />,
    permissions: p.adminSessionManagement,
    children: [
      {
        name: 'training_rooms',
        href: 'admin/session/training-rooms',
        permissions: p.adminSessionTrainingRooms,
      }, {
        name: 'cancellation_reasons',
        href: 'admin/session/cancellation-reasons',
        permissions: p.adminSessionCancelationReasons,
      }, {
        name: 'absence_reasons',
        href: 'admin/session/absence-reasons',
        permissions: p.adminSessionAbsenceReasons,
      }, {
        name: 'admin_costs',
        href: 'admin/session/costs',
        permissions: p.adminSessionCosts,
      }, {
        name: 'menu.admin_animation_locales',
        href: 'admin/animation-locales',
        permissions: p.adminAnimationLocales,
      }, {
        name: 'menu.admin_session_types',
        href: 'admin/session/types',
        permissions: p.adminSessionTypes,
      }, {
        name: 'menu.admin_session_locations',
        href: 'admin/session/locations',
        permissions: p.adminSessionLocations,
      }, {
        name: 'menu.admin_session_tasks',
        href: 'admin/session/tasks',
        permissions: p.adminSessionTasks,
      }, {
        name: 'menu.admin_session_task_lists',
        href: 'admin/session/task-lists',
        permissions: p.adminSessionTasks,
      }, {
        name: 'session.custom-fields.title',
        href: 'admin/session/custom-fields',
        permissions: p.adminCustomFields,
      }, {
        name: 'session.room-layouts.title',
        href: 'admin/session/room-layouts',
      }, {
        name: 'session.teaching-materials.title',
        href: 'admin/session/teaching-materials',
      },
    ],
  }, {
    // Training Plan
    name: 'menu.admin_plan',
    icon: <SettingFilled />,
    permissions: p.adminTrainingPlan,
    children: [
      {
        name: 'cancellation_reason',
        href: 'admin/training-wish/cancellation-reasons',
      },
    ],
  }, {
    // Organizations
    name: 'menu.admin_users_organizations',
    icon: <SettingFilled />,
    permissions: p.adminUsersOrganizations,
    children: [
      {
        name: 'admin_organization',
        href: 'admin/session/organizations',
      }, {
        name: 'admin_organization_type',
        href: 'admin/session/organization-types',
      },
    ],
  }, {
    // Matrix
    name: 'menu.admin_matrix',
    icon: <SettingFilled />,
    permissions: p.adminMatrix,
    children: [
      {
        name: 'admin_matrix_level_templates',
        href: 'admin/matrix/level-templates',
      }, {
        name: 'admin_matrix_skill_types',
        href: 'admin/matrix/skill-types',
      }, {
        name: 'admin_matrix_skills',
        href: 'admin/matrix/skills',
      }, {
        name: 'admin_matrix_job_family',
        href: 'admin/matrix/job-families',
      }, {
        name: 'admin_matrix_jobs',
        href: 'admin/matrix/jobs',
      },
    ],
  }, {
    // Surveys
    name: 'menu.admin_survey',
    icon: <SettingFilled />,
    permissions: p.adminSurveys,
    children: [
      {
        name: 'label_surveys',
        href: 'admin/surveys',
      },
    ],
  }, {
    // Emails
    name: 'menu.admin_email',
    icon: <SettingFilled />,
    permissions: p.adminEmails,
    children: [
      {
        name: 'admin_email_templates',
        href: 'admin/email-templates',
        permissions: p.adminEmailTemplates,
      }, {
        name: 'menu.admin_email_scheduled',
        href: 'admin/email-scheduled',
        permissions: p.adminEmailScheduled,
      }, {
        name: 'menu.admin_email_sent',
        href: 'admin/email-sent',
        permissions: p.adminEmailSent,
      },
    ],
  }, {
    // Imports
    name: 'menu.admin_import',
    icon: <SettingFilled />,
    permissions: p.adminImports,
    children: [
      {
        name: 'label.import',
        href: 'admin/import',
      },
    ],
  }, {
    // Automations
    name: 'menu.admin_automations',
    icon: <SettingFilled />,
    permissions: p.adminAutomations,
    children: [
      {
        name: 'label.automations',
        href: 'admin/automations',
      },
    ],
  }, {
    // HSE
    name: 'menu.admin_accreditation_management',
    icon: <SettingFilled />,
    permissions: p.adminAccreditationManagement,
    children: [
      {
        name: 'menu.admin_accreditation_domains',
        href: 'admin/accreditation/domains',
      },
      {
        name: 'menu.admin_accreditations',
        href: 'admin/accreditation/accreditations',
      },
      {
        name: 'menu.admin_accreditation_prerequisites',
        href: 'admin/accreditation/prerequisites',
      },
      {
        name: 'menu.admin_accreditation_signings',
        href: 'admin/accreditation/signings',
      },
      {
        name: 'menu.admin_accreditation_application_fields',
        href: 'admin/accreditation/application-fields',
      },
      {
        name: 'menu.admin_accreditation_mail_configuration',
        href: 'admin/accreditation/trigger-mails',
      },
    ],
  },
]
