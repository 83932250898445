const sessionEdit = 'SESSION_CAN_EDIT'
const sessionView = 'SESSION_CAN_VIEW_LIST'

export const permissions = {
  catalog: ['CATALOG_CAN_SEE_CATALOG'],
  trainingManagement: ['CATALOG_MANAGEMENT'],
  myPerimeter: ['PERIMETER_CAN_VIEW'],
  needsSummary: ['TRAINING_WISH_CAN_VIEW'],
  sessions: [sessionEdit, sessionView],
  sessionEdit: [sessionEdit],
  sessionEditTask: ['SESSION_CAN_EDIT_TASK'],
  sessionView: [sessionView],
  sessionViewTask: ['SESSION_CAN_VIEW_TASK'],
  trainingPlan: ['TRAINING_PLAN_CAN_VIEW'],
  trainingHistory: ['TRAINING_HISTORY_CAN_VIEW'],
  deletedWishes: ['ADMIN_REMOVED_TRAINING_WISH'],
  accreditationBoard: ['ACCREDITATION_CAN_SEE_WISH'],
  renewableTrainingBoard: ['RENEWABLE_TRAINING_DASHBOARD'],
  collectives: ['COLLECTIVE_CAN_VIEW'],
  reporting: ['PARTNERS_REPORTING'],
  adminProfiles: ['ADMIN_MANAGE_PROFILE'],
  adminSubProfiles: ['ADMIN_MANAGE_PROFILE'],
  adminSettings: ['ADMIN_SETTINGS'],
  adminCollaborators: ['ADMIN_EXTERNAL_COLLABORATORS', 'ADMIN_COLLABORATORS'],
  adminExternalCollaborators: ['ADMIN_EXTERNAL_COLLABORATORS', 'ADMIN_COLLABORATORS'],
  adminDocTemplates: ['ADMIN_DOC_TEMPLATE'],
  adminPopulations: ['ADMIN_POPULATIONS'],
  adminApplications: ['ADMIN_SETTINGS'],
  adminTrainings: ['ADMIN_TRAININGS'],
  adminWishes: ['ADMIN_WISH'],
  priorities: ['TRAINING_WISH_CAN_VIEW_PRIORITY'],
  adminVintages: ['ADMIN_VINTAGE'],
  adminSessionTrainingRooms: ['SESSION_ADMIN_TRAINING_ROOMS'],
  adminSessionManagement: ['SESSION_ADMIN_MANAGEMENT'],
  adminSessionCancelationReasons: ['SESSION_ADMIN_MANAGEMENT'],
  adminSessionAbsenceReasons: ['SESSION_ADMIN_MANAGEMENT'],
  adminSessionCosts: ['SESSION_ADMIN_MANAGEMENT'],
  adminCustomFields: ['ADMIN_CUSTOM_FIELDS'],
  adminAnimationLocales: ['SESSION_ADMIN_MANAGEMENT'],
  adminSessionTypes: ['SESSION_ADMIN_MANAGEMENT'],
  adminSessionLocations: ['SESSION_ADMIN_MANAGEMENT'],
  adminSessionTasks: ['SESSION_CAN_EDIT_TASK'],
  adminTimezones: ['ADMIN_TIMEZONE'],
  adminTrainingPlan: ['ADMIN_TRAINING_PLAN'],
  adminUsersOrganizations: ['ADMIN_USERS_AND_ORGANIZATIONS'],
  adminMatrix: ['ADMIN_MATRIX'],
  adminSurveys: ['ADMIN_SURVEY'],
  adminSurveysCanEdit: ['SURVEY_CAN_EDIT'],
  adminEmails: ['ADMIN_EMAIL'],
  adminEmailTemplates: ['ADMIN_EMAIL_TEMPLATE'],
  adminEmailScheduled: ['EMAIL_MONITOR'],
  adminEmailSent: ['EMAIL_MONITOR'],
  adminImports: ['ADMIN_IMPORT_CREATE', 'ADMIN_IMPORT_UPDATE'],
  adminAutomations: ['ADMIN_AUTOMATION'],
  adminAccreditationManagement: ['ACCREDITATION_MANAGEMENT'],
  adminPerimetersTraining: ['ADMIN_SETTINGS'],
  adminPerimetersAccreditation: ['ADMIN_ACCREDITATION_PERIMETER'],
  roomBookingCanView: ['ROOM_BOOKING_CAN_VIEW'],
  roomBookingCanAdd: ['ROOM_BOOKING_CAN_ADD'],
  roomBookingCanDelete: ['ROOM_BOOKING_CAN_DELETE'],
}
