import * as React from 'react'
import { useI18n } from 'context/I18nProvider'
import { getId } from 'services/apiPlatform'
import BaseAsyncSelect from 'components/Filters/BaseAsyncSelect'
import { API_TRAINING_CATEGORIES } from 'constants/api-v2'

export default function Categories({ ...props }) {
  const { i18name } = useI18n()

  return (
    <BaseAsyncSelect
      name="category"
      label="label.categories"
      url={API_TRAINING_CATEGORIES}
      mapper={(opt: any) => ({ ...opt, label: i18name(opt), value: getId(opt) })}
      apiPlatform
      {...props}
    />
  )
}
