import * as React from 'react'
import { Dropdown as AntdDropdown } from 'antd'
import type { DropDownProps as AntdDropDownProps } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { styledIcon } from 'services/icon'

// https://github.com/react-component/menu/blob/2b830a14082f9f17148723501f1202aed5f4d368/src/interface.ts#L94
export interface MenuInfo {
  key: string
  keyPath: string[]
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
}

export interface ItemProps {
  key: string
  label?: string | React.ReactNode
  onClick?: (event: MenuInfo) => void
  icon?: any
  disabled?: boolean
}

interface DropDownProps {
  items?: ItemProps[]
  placement?: string
  className?: string
  iconStyle?: object
}

export default function Dropdown({
  items,
  children,
  placement = 'bottomRight',
  className = '',
  iconStyle = {},
  ...props
}: DropDownProps & AntdDropDownProps) {
  const [isOpen, setOpen] = useState(false)

  const content = children || (
    <div className={`hover:cursor-pointer ${className}`}>
      <MoreOutlined style={iconStyle} />
    </div>
  )

  const { menu } = props

  return (
    <AntdDropdown
      trigger={['click']}
      open={isOpen && ((!!items && items?.length > 0) || (!!menu && menu.items && menu.items?.length > 0))}
      onOpenChange={setOpen}
      menu={typeof menu !== 'undefined' ? menu : {
        items: (items || []).map(item => ({
          ...item,
          // Bigger icons
          ...('icon' in item
            ? { icon: styledIcon(item.icon, { fontSize: '1.1rem' }) }
            : {}
          ),
        })),
      }}
      {...props}
      placement={placement}
    >
      {content}
    </AntdDropdown>
  )
}
