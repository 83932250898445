import * as React from 'react'
import { useRouteLoaderData } from 'react-router-dom'
import type { Settings } from 'shared/types'
import { useQuery } from 'context/QueryProvider'
import Switch from 'components/UI/Switch'
import { API_URL_ADMIN_ACCREDITATION_SWITCH as apiURL } from 'constants/api'

function HseSwitcher() {
  const settings = useRouteLoaderData('root') as Settings
  const { hseEnabled } = settings
  const { client } = useQuery()
  const [loading, setLoading] = React.useState(false)

  const handleChange = async (checked: boolean) => {
    setLoading(true)

    await client.post(apiURL, checked)
    document.location.reload()
  }

  return (
    <Switch
      checked={Boolean(hseEnabled)}
      onChange={handleChange}
      loading={loading}
      checkedChildren="HSE"
      unCheckedChildren="HSE"
    />
  )
}

export default HseSwitcher
