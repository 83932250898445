import * as React from 'react'
import PropTypes from 'prop-types'
import { useRouteLoaderData } from 'react-router-dom'

const ProfileContext = React.createContext()

export default function ProfileContextProvider({ children }) {
  const userData = useRouteLoaderData('user')
  const user = React.useMemo(() => userData || undefined, [userData])

  const hasPermission = React.useCallback(permission => (
    (user?.permissions || []).some(p => p.code === permission)
  ), [user?.permissions])

  const value = React.useMemo(() => ({
    profile: user?.profile,
    profiles: (user?.profiles || []),
    hasPermission,
  }), [user, hasPermission])

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  )
}

ProfileContextProvider.propTypes = {
  children: PropTypes.node,
}

export function useProfile() {
  const context = React.useContext(ProfileContext)

  if (context === undefined) {
    throw new Error('ProfileContext must be used within a Provider')
  }

  return context
}

ProfileContextProvider.propTypes = {
  children: PropTypes.node,
}
